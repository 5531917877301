import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'container',
    'title',
    'content',
    'leftButton',
    'rightButton',
  ];

  open(event) {
    if (this.hasContainerTarget) {
      const {
        title: title,
        content: content,
        leftButtonText: leftButtonText,
        leftButtonUrl: leftButtonUrl = '#',
        rightButtonText: rightButtonText,
        rightButtonUrl: rightButtonUrl = '#',
        rightButtonDisabled: rightButtonDisabled = false,
      } = event.params;

      if (this.hasTitleTarget && title) {
        this.titleTarget.innerText = title;
      }

      if (this.hasContentTarget && content) {
        this.contentTarget.innerHTML = content;
      }

      if (this.hasLeftButtonTarget) {
        if (leftButtonText) {
          this.leftButtonTarget.innerText = leftButtonText;
        }
        this.leftButtonTarget.href = leftButtonUrl;
      }

      if (this.hasRightButtonTarget && !rightButtonDisabled) {
        if (rightButtonText) {
          this.rightButtonTarget.innerText = rightButtonText;
        }
        this.rightButtonTarget.href = rightButtonUrl;
        this.rightButtonTarget.classList.remove('hol-hidden');
      } else if (this.hasRightButtonTarget && rightButtonDisabled) {
        this.rightButtonTarget.classList.add('hol-hidden');
      }

      this.containerTarget.classList.remove('hol-hidden');
    }
  }

  close(e) {
    e.preventDefault();
    const element = e.target;
    // if element is a link
    if (element.tagName === 'A' && element.getAttribute('href') != '#') {
      const href = element.getAttribute('href');
      Turbo.visit(href);
    } else {
      if (this.hasContainerTarget) {
        this.containerTarget.classList.add('hol-hidden');
      }
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
