import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"]

  toggleInputs() {
    this.itemTargets.forEach((item) => {
      item.disabled = !item.disabled;  // Toggle the 'disabled' state
    });
  }
}
